import CheckWebpFeature from "./src/common/ggfx-client/module/webp-checker"
export { wrapRootElement } from "./gatsby-shared"
CheckWebpFeature()

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location.search.indexOf("price=9000000") > 0) {
    window.location.replace("/")
  }
  if (location.search.indexOf("Itemid") > 0) {
    window.location.replace("/")
  }

  // if(location.pathname==="/properties/"||location.pathname==="/properties"){
  //   window.location.replace("/properties/for-sale/in-dubai/");
  // }

  if (
    location.pathname === "/new-developments-details/" ||
    location.pathname === "/new-developments-details"
  ) {
    window.location.replace("/off-plan-properties/for-sale/in-dubai/")
  }

  if (location.pathname.includes("developments-of")) {
    const url = location.pathname.split("-in-")
    if (
      !url[1]?.includes("over") ||
      !url[1]?.includes("between") ||
      !url[1]?.includes("under")
    ) {
      const locationToRedirect = url[1]
        .replace(/-(between|over|under).*/, "")
        .trim()
      window.location.replace(
        `/off-plan-properties/for-sale/in-${locationToRedirect}/`
      )
    }
  } else if (location.pathname.includes("/new-developments/properties")) {
    const newUrl = location.pathname.split("-in-")

    if (newUrl[1]) {
      window.location.replace(`/off-plan-properties/for-sale/in-${newUrl[1]}/`)
    }
  }
}
